
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import moment from 'moment'
import EChart from './EChart.vue'
import useSpotlights from '@/models/list/spotlight_videos'
import useKpiResults from '@/models/list/kpi_results'
import _ from 'lodash'
import { useRoute, useRouter } from 'vue-router'
import { useUrlSearchParams } from '@vueuse/core'

export default defineComponent({
  name: 'KPIAnalytics',
  components: {
    EChart,
  },
  setup() {
    const { fetchItems: fetchSpotlights, items: spotlights } = useSpotlights()
    const { fetchItems: fetchKpiResults, items: kpiResults } = useKpiResults()
    const from = ref(moment().subtract(7, 'd').format('YYYY-MM-DDTHH:mm'))
    const to = ref(moment().format('YYYY-MM-DDTHH:mm'))
    const counts = ref<number[]>([])
    const procedure = ref('')
    // const sort = ref('dateTime')
    const dates = ref<string[]>([])
    const kpi1 = ref<number[]>([])
    const kpi2 = ref<number[]>([])
    const kpi3 = ref<number[]>([])
    const kpi4 = ref<number[]>([])
    const kpi5 = ref<number[]>([])
    const mAP = ref<number[]>([])
    const factory = ref('')
    const customer = ref('')
    const tag = ref('')
    // const presetMap = ref(new Map<string, string>())
    const factoryMap = ref(new Map<string, [string]>())
    const customers = ref<string[]>([])
    const tags = ref(new Set())
    const factories = ref<string[]>([])
    const factorySelection = ref<string[]>([])
    const flag = ref(false)
    const o = ref()
    // const selectedGroup = ref('')
    const Gparams = ref<string[]>([])
    const Gkpi1 = ref<number[]>([])
    const Gkpi2 = ref<number[]>([])
    const Gkpi3 = ref<number[]>([])
    const Gkpi4 = ref<number[]>([])
    const Gkpi5 = ref<number[]>([])
    const GmAP = ref<number[]>([])
    // const multiGroup = ref()
    const selectedGroup = ref<string[]>([])

    const sort = computed(() => {
      let s = [] as string[]
      s = ['recordedDate.seconds', 'recordedDate.nanos']
      return s.join(',')
    })
    const toTimestamp = (value: string): number => {
      const date = new Date(value)
      return date.valueOf() / 1000
    }
    const toUTCDate = (date: string): string => {
      const local = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')

      return local
    }

    const spotlightFilter = computed(() => {
      const q: unknown[] = []
      if (from.value || to.value) {
        q.push({ 'recordedDate.seconds': { $gte: toTimestamp(toUTCDate(from.value)) } })
        q.push({ 'recordedDate.seconds': { $lte: toTimestamp(toUTCDate(to.value)) } })
      }
      if (tag.value) {
        q.push({ tags: { $in: [tag.value] } })
      }
      if (customer.value) {
        q.push({ customer: customer.value })
      }
      if (factory.value) {
        q.push({ preset: { $in: factoryMap.value.get(factory.value) } })
      }
      return q.length > 0
        ? JSON.stringify({
          $and: q,
        })
        : ''
    })
    const kpiFilter = computed(() => {
      const q: unknown[] = []
      if (from.value || to.value) {
        q.push({ 'created.seconds': { $gte: toTimestamp(from.value) } })
        q.push({ 'created.seconds': { $lte: toTimestamp(to.value) } })
      }

      return q.length > 0
        ? JSON.stringify({
          $and: q,
        })
        : ''
    })

    const gatherData = async () => {
      o.value = null
      flag.value = false
      factories.value = []
      dates.value = []
      kpi1.value = []
      kpi2.value = []
      kpi3.value = []
      kpi4.value = []
      kpi5.value = []

      const arr = Object.values(spotlightData.value)
      const d = Object.keys(spotlightData.value)

      for (let i = 0; i < arr.length; i++) {
        dates.value.push(d[i])
        const k = [0, 0, 0, 0, 0, 0]
        const values = []
        const objects = []
        let cnt1 = 0
        let cnt2 = 0
        let cnt3 = 0
        let cnt4 = 0
        let cnt5 = 0
        let cntm = 0
        for (let j = 0; j < arr[i].length; j++) {
          if (spotlightMapKpi.value[String(arr[i][j].name)] !== undefined) {
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi1 !== -1) {
              k[0] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi1)
              cnt1 += 1
              values.push(Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi1))
              objects.push(spotlightMapKpi.value[String(arr[i][j].name)])
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi2 !== -1) {
              k[1] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi2)
              cnt2 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi3 !== -1) {
              k[2] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi3)
              cnt3 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi4 !== -1) {
              k[3] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi4)
              cnt4 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi5 !== -1) {
              k[4] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi5)
              cnt5 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.mAP !== -1) {
              k[5] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.mAP)
              cntm += 1
            }
          }
        }
        kpi1.value.push(Number((k[0] / cnt1).toFixed(2)))
        kpi2.value.push(Number((k[1] / cnt2).toFixed(2)))
        kpi3.value.push(Number((k[2] / cnt3).toFixed(2)))
        kpi4.value.push(Number((k[3] / cnt4).toFixed(2)))
        kpi5.value.push(Number((k[4] / cnt5).toFixed(2)))
        mAP.value.push(Number((k[5] / cntm).toFixed(2)))
      }
    }
    const gatherGroupData = async () => {
      flag.value = false
      Gparams.value = []
      Gkpi1.value = []
      Gkpi2.value = []
      Gkpi3.value = []
      Gkpi4.value = []
      Gkpi5.value = []
      GmAP.value = []
      const arr = Object.values(multiGroup.value)
      const d = Object.keys(multiGroup.value)
      for (let i = 0; i < arr.length; i++) {
        Gparams.value.push(d[i])
        const k = [0, 0, 0, 0, 0, 0]
        let cnt1 = 0
        let cnt2 = 0
        let cnt3 = 0
        let cnt4 = 0
        let cnt5 = 0
        let cntm = 0
        for (let j = 0; j < arr[i].length; j++) {
          if (spotlightMapKpi.value[String(arr[i][j].name)] !== undefined) {
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi1 !== -1) {
              k[0] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi1)
              cnt1 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi2 !== -1) {
              k[1] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi2)
              cnt2 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi3 !== -1) {
              k[2] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi3)
              cnt3 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi4 !== -1) {
              k[3] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi4)
              cnt4 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.kpi5 !== -1) {
              k[4] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.kpi5)
              cnt5 += 1
            }
            if (spotlightMapKpi.value[String(arr[i][j].name)]?.mAP !== -1) {
              k[5] += Number(spotlightMapKpi.value[String(arr[i][j].name)]?.mAP)
              cntm += 1
            }
          }
        }
        Gkpi1.value.push(Number((k[0] / cnt1).toFixed(2)))
        Gkpi2.value.push(Number((k[1] / cnt2).toFixed(2)))
        Gkpi3.value.push(Number((k[2] / cnt3).toFixed(2)))
        Gkpi4.value.push(Number((k[3] / cnt4).toFixed(2)))
        Gkpi5.value.push(Number((k[4] / cnt5).toFixed(2)))
        GmAP.value.push(Number((k[5] / cntm).toFixed(2)))
      }
    }
    // group spotlight with date
    const spotlightData = computed(() => {
      const t = _.groupBy(spotlights.value, function (b) {
        const localDate = moment.utc(b.recordedDate).local().format('YYYY-MM-DD HH:mm:ss')
        return localDate.split(' ')[0]
      })
      return t
    })
    // const groupedData = computed(() => {
    //   const t = _.groupBy(kpiResults.value, function (b) {
    //     const localDate = moment.utc(b.created).local().format('YYYY-MM-DD HH:mm:ss')
    //     return localDate.split(' ')[0]
    //   })
    //   return t
    // })

    watch(selectedGroup, async () => {
      await gatherGroupData()
    })

    const spotlightMapKpi = computed(() => Object.fromEntries(
      spotlights.value.map(u => [
        u.name,
        kpiResults.value.find(s => s.name?.includes(u.name as string)),
      ]),
    ))

    const multiGroup = computed(() => {
      const t = _.groupBy(spotlights.value, function (b) {
        const localDate = moment.utc(b.recordedDate).local().format('YYYY-MM-DD HH:mm:ss').split(' ')[0]
        const group = []
        group.push(localDate)
        if (selectedGroup.value.includes('Customer')) {
          group.push(b.customer)
        }
        if (selectedGroup.value.includes('Factory')) {
          group.push(b.factory)
        }
        return group
      })
      return t
    })

    const fetch = debounce(async () => {
      await fetchKpiResults({
        pageSize: -1,
        spotlight: '-',
        orderBy: sort.value,
        filter: kpiFilter.value,
      })
      await fetchSpotlights({
        pageSize: -1,
        orderBy: sort.value,
        filter: spotlightFilter.value,
      })
      await gatherData()
      flag.value = true
    }, 250)

    const urlParams = useUrlSearchParams()
    onMounted(async () => {
      if (urlParams.from) from.value = urlParams.from as string
      if (urlParams.to) to.value = urlParams.to as string

      await Promise.all([
        fetch(),
      ])
    })

    const queryParams = computed(() => ({
      from: from.value,
      to: to.value,

    }))

    const router = useRouter()
    const route = useRoute()
    watch([from, to], () => {
      router.replace({
        query: Object.fromEntries(
          Object.entries(
            Object.assign({}, route.query, queryParams.value),
          ).filter(([, v]) => !!v)),
      })
    })
    watch([spotlightFilter, kpiFilter, sort], () => {
      fetch()
    })

    watch(flag, () => {
      const target = ref<number[]>([])
      if (spotlights.value.length > 0 && flag.value === true) {
        for (let i = 0; i < dates.value.length; i++) {
          target.value.push(0.95)
        }
        if (target.value.length > 0) {
          o.value = {
            title: {
              text: 'Performance Line',
            },
            tooltip: {
              trigger: 'axis',
              position: 'top',
              appendToBody: true,
            },
            legend: {
              data: ['kpi_1', 'kpi_2', 'kpi_3', 'kpi_4', 'kpi_5', 'mAP', 'threshold'],
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: dates.value,
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                name: 'kpi_1',
                type: 'line',
                data: kpi1.value,
              },
              {
                name: 'kpi_2',
                type: 'line',
                data: kpi2.value,
              },
              {
                name: 'kpi_3',
                type: 'line',
                data: kpi3.value,
              },
              {
                name: 'kpi_4',
                type: 'line',
                data: kpi4.value,
              },
              {
                name: 'kpi_5',
                type: 'line',
                data: kpi5.value,
              },
              {
                name: 'mAP',
                type: 'line',
                data: mAP.value,
              },
              {
                name: 'threshold',
                type: 'line',
                lineStyle: { color: 'green', width: 3, type: 'dotted' },
                data: target.value,
              },
            ],
          }
        }
      }
    })
    return {
      from,
      to,
      counts,
      procedure,
      spotlights,
      o,
      factory,
      customer,
      tag,
      sort,
      customers,
      tags,
      factories,
      factorySelection,
      kpi1,
      kpi2,
      kpi3,
      kpi4,
      kpi5,
      mAP,
      dates,
      selectedGroup,
      Gkpi1,
      Gkpi2,
      Gkpi3,
      Gkpi4,
      Gkpi5,
      GmAP,
      Gparams,
    }
  },
})
